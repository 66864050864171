export const spacing = {
	4: '0.25rem',
	6: '0.375rem',
	8: '0.5rem',
	10: '0.625rem',
	12: '0.75rem',
	14: '0.875rem',
	16: '1rem',
	18: '1.125rem',
	20: '1.25rem',
	22: '1.375rem',
	24: '1.5rem',
	26: '1.625rem',
	28: '1.75rem',
	30: '1.875rem',
	32: '2rem',
	36: '2.25rem',
	40: '2.5rem',
	42: '2.625rem',
	46: '2.875rem',
	48: '3rem',
	52: '3.25rem',
	56: '3.5rem',
	60: '3.75rem',
	64: '4rem',
	70: '4.375rem',
	72: '4.5rem',
	76: '4.75rem',
	80: '5rem'
};
